import * as React from "react";

function Stripes({ fill, height, width }) {
	return (
		<svg
			aria-hidden="true"
			height={height || 423.81}
			fill={fill || "rgba(255, 255, 255, 12)"}
			viewBox="0 0 429.4 423.81"
		>
			<path d="M429.4 0L0 124.5v34.685zm0 38.084L0 162.584v34.685zm0 38.084L0 200.668v34.685zm0 38.084L0 238.752v34.689zm0 38.089L0 276.841v34.684zm0 38.084L0 314.925v34.684zm0 38.084L0 353.009v34.684zm0 38.084L0 391.093v8.441c0 8.685 1.91 16.904 5.316 24.278z" />
		</svg>
	);
}

const MemoStripes = React.memo(Stripes);
export default MemoStripes;
