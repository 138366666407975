import React from "react";
import Layout from "src/components/Layout";
import HeadSEO from "src/components/HeadSEO";
import {
	Box,
	Heading,
	Container,
	Text,
	VStack,
	HStack,
	UnorderedList,
	ListItem,
	Divider,
	Grid,
	Button,
	Tooltip,
	Flex,
} from "@chakra-ui/react";
import * as Icon from "react-icons/hi";
import { BiShield, BiWrench } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import Stripes from "src/components/Stripes";
import EAIcon from "src/components/EAIcon";
import ARCTick from "src/components/ARCTick";

const index = () => {
	return (
		<Layout>
			<HeadSEO
				title="Heat Pumps, Air Conditioning & Refrigeration | Emery Air"
				slug="/"
			/>
			<Box
				as="header"
				color="white"
				zIndex="3"
				position="absolute"
				w="100%"
				top="1.5rem"
			>
				<Box w="87.5vw" mx="auto" maxW="container.xl">
					<Flex justify="space-between" align="center">
						<Box width={["24px", "24px", "36px", "48px"]} color="blue.500">
							<EAIcon size="100%" />
						</Box>
						<HStack spacing="1rem">
							<Tooltip label="ARC #AU41597" aria-label="ARC #AU41597">
								<Box
									padding="0.75rem"
									bg="white"
									width="5rem"
									borderRadius="0.5rem"
								>
									<ARCTick />
								</Box>
							</Tooltip>
							<Box
								as="a"
								aria-label="call Emery Air"
								href="tel:0418 616 942"
								width="36px"
								color="white"
								p="0.45rem"
								borderRadius="0.5rem"
								bg="blue.400"
							>
								<Icon.HiPhone size="100%" />
							</Box>
							<Box
								as="a"
								aria-label="email Emery Air"
								href="mailto:emeryair@bigpond.com"
								width="36px"
								color="white"
								p="0.45rem"
								borderRadius="0.5rem"
								bg="blue.400"
							>
								<Icon.HiMail size="100%" />
							</Box>
						</HStack>
					</Flex>
				</Box>
			</Box>
			<Box
				as="section"
				bgGradient="linear(to-r, blue.900, blue.700)"
				color="white"
				position="relative"
				py="8rem"
				overflow="hidden"
			>
				<Box
					position="absolute"
					w={["145vw", "145vw", "45vw"]}
					left={["-60vw", "-60vw", "-4vw"]}
					overflow="hidden"
					top={["2rem", "2rem", "-2rem"]}
					opacity="0.2"
				>
					<Stripes width="auto" height="100%" />
				</Box>
				<Box
					w="87.5vw"
					mx="auto"
					maxW="container.md"
					position="relative"
					zIndex="2"
				>
					<Grid gridTemplateColumns={[null, null, "3fr 1fr"]} gap="4rem">
						<VStack align="flex-start" spacing="2rem">
							<HStack color="blue.500" spacing="1rem">
								<EAIcon size={72} />
								<Text
									color="white"
									fontSize="3rem"
									position="relative"
									top="3px"
								>
									Emery Air
								</Text>
							</HStack>
							<Heading color="blue.400" as="span" display="block" size="lg">
								Your temperature, controlled
							</Heading>
							<Heading size="2xl">
								Heat Pumps, Air Conditioning, and Refrigeration
							</Heading>
						</VStack>
						<VStack align="flex-start" justify="center" spacing="2rem">
							<Heading
								pl="1rem"
								borderLeft="5px solid"
								borderColor="blue.500"
								size="lg"
							>
								Commercial
							</Heading>
							<Heading
								pl="1rem"
								borderLeft="5px solid"
								borderColor="blue.500"
								size="lg"
							>
								Residential
							</Heading>
						</VStack>
					</Grid>
				</Box>
			</Box>
			<Box as="section" py="8rem" background="gray.50" color="gray.800">
				<Box w="87.5vw" mx="auto" maxW="container.md">
					<VStack spacing="2rem" align="flex-start">
						<Heading as="h2">
							Tasmanian Temperature <br /> Experts for Over 20 years
						</Heading>
						<Text>
							Emery Air has been established since August of 2008 to provide
							HVAC services to Southern Tasmania, but we have carried out work
							all over our beautiful state of Tasmania.
						</Text>
						<Text>
							We believe the experience within Emery Air and our history of
							satisfied commercial and residential clients shines above our
							competitors.
						</Text>
						<Text>
							We began with a pride in providing consistent values and
							reliability when it comes to our customers’ needs and that
							philosophy has continued through the years and throughout our
							workforce.
						</Text>
						<Text>
							Emery Air offers all Air conditioning and heat pump servicing
							installation and repairs, from residential to commercial, we can
							also assist with ventilation and refrigeration service and
							installation and repairs.
						</Text>
					</VStack>
					<Divider my="4rem" />
					<Heading as="h2">Our Services</Heading>
				</Box>
				<Box w="87.5vw" mx="auto" maxW="container.xl" mt="4rem">
					<Grid
						gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
						gap="3rem"
					>
						<VStack
							align="flex-start"
							spacing="2rem"
							gridColumn={[null, null, "span 2"]}
						>
							<Box color="white" p="0.45rem" borderRadius="1rem" bg="blue.400">
								<BiShield size={36} />
							</Box>
							<Heading as="h3" size="md">
								Heat Pump Servicing
							</Heading>
							<Text>
								In Tasmania we call them heat pumps but on the mainland it’s a
								“reverse cycle air conditioner or air conditioner”. Servicing by
								our trained technicians will ensure the heat pump is operating
								at its best, or to pick up on any potential problems that might
								simply be fixed before it becomes a major problem. Please feel
								free to pick up the phone and ask us about servicing costs.
							</Text>
						</VStack>
						<VStack align="flex-start" spacing="2rem">
							<Box color="white" p="0.45rem" borderRadius="1rem" bg="blue.400">
								<BiWrench size={36} />
							</Box>
							<Heading as="h3" size="md">
								Heat Pump Repairs
							</Heading>
							<Text>
								Should you experience a break down, we are able to provide good
								advice and a quote if necessary to help you make the decision to
								repair the heat pump or to replace it.
							</Text>
						</VStack>
						<VStack align="flex-start" spacing="2rem">
							<Box color="white" p="0.45rem" borderRadius="1rem" bg="blue.400">
								<BsGear size={36} />
							</Box>
							<Heading as="h3" size="md">
								Heat Pump Installation
							</Heading>
							<Text>
								We can assist with installing a new heat pump for you or
								replacing an old one. Whether you are removing an old wood fire
								or removing electric heating, we can provide experienced advice
								to help you decide.
							</Text>
						</VStack>
						<VStack align="flex-start" spacing="2rem">
							<Box color="white" p="0.45rem" borderRadius="1rem" bg="blue.400">
								<BiWrench size={36} />
							</Box>
							<Heading as="h3" size="md">
								Ventilation
							</Heading>
							<Text>
								Residential and commercial exhaust and supply air ventilation
								installations to be compliant with AS1668.
							</Text>
						</VStack>

						<VStack align="flex-start" spacing="2rem">
							<Box color="white" p="0.45rem" borderRadius="1rem" bg="blue.400">
								<BiShield size={36} />
							</Box>
							<Heading as="h3" size="md">
								Refrigeration Service
							</Heading>
							<Text>
								Our team has technicians also available for servicing or repairs
								to cool rooms and freezers for local supermarkets, pubs, and
								small shops.
							</Text>
						</VStack>
					</Grid>
				</Box>
			</Box>
			<Box
				as="section"
				bgGradient="linear(to-l, blue.900, blue.700)"
				position="relative"
				overflow="hidden"
				color="white"
				py="8rem"
			>
				<Box
					position="absolute"
					transform="rotateY(180deg)"
					w={["145vw", "145vw", "45rem"]}
					right={["-80vw", "-80vw", "-4rem"]}
					overflow="hidden"
					top={["2rem", "2rem", "-5rem"]}
					opacity="0.2"
				>
					<Stripes width="auto" height="100%" />
				</Box>
				<Box w="87.5vw" mx="auto" maxW="container.md">
					<VStack spacing="1.5rem">
						<Heading
							as="span"
							display="block"
							textAlign="center"
							size="md"
							lineHeight="1.6"
						>
							Emery Air is a full Heat Pump, Air Conditioning and Refrigeration
							service/breakdown and installation provider with experience in
							residential and commercial fields for over 20 years.
						</Heading>
						<HStack spacing="1rem">
							<Button
								as="a"
								href="tel:0418 616 942"
								borderRadius="full"
								p="1.63rem"
								bg="blue.500"
								size="lg"
								fontSize="xl"
								_hover={{ background: "blue.500" }}
							>
								Call Us
							</Button>
							<Button
								as="a"
								href="mailto:emeryair@bigpond.com"
								borderRadius="full"
								p="1.63rem"
								bg="white"
								color="black"
								size="lg"
								fontSize="xl"
							>
								Email Us
							</Button>
						</HStack>
					</VStack>
				</Box>
			</Box>
			<Box as="section" py="8rem" background="gray.50" color="gray.800">
				<Box w="87.5vw" mx="auto" maxW="container.md">
					<Heading
						as="span"
						display="block"
						textAlign="center"
						size="md"
						lineHeight="1.6"
					>
						Emery Air is a licensed business and ARC registered with experienced
						technicians that provide professional and prompt assistance to our
						customers’ needs within Tasmania.
					</Heading>
				</Box>
			</Box>
			<Box as="footer" bg="blue.800" color="white" py="8rem">
				<VStack spacing="2rem">
					<HStack color="blue.500" spacing="1rem">
						<EAIcon size={48} />
						<Text color="white" fontSize="2rem" position="relative" top="3px">
							Emery Air
						</Text>
					</HStack>
					<VStack fontSize="1.25rem" fontWeight="bold" spacing="0.5rem">
						<Text
							textDecoration="underline rgba(255, 255, 255, 0.3)"
							sx={{ textUnderlineOffset: "0.1rem" }}
						>
							<a href="tel:0418 616 942">0418 616 942</a>
						</Text>
						<Text
							textDecoration="underline rgba(255, 255, 255, 0.3)"
							sx={{ textUnderlineOffset: "0.1rem" }}
						>
							<a href="mailto:emeryair@bigpond.com">emeryair@bigpond.com</a>
						</Text>
						<Text>ABN: 92 606 669 130</Text>
					</VStack>
					<HStack spacing="1rem">
						<Box
							padding="0.75rem"
							bg="white"
							width="7rem"
							borderRadius="0.5rem"
						>
							<ARCTick />
						</Box>
						<Text fontWeight="bold" fontSize="lg">
							AU41597
						</Text>
					</HStack>
				</VStack>
			</Box>
		</Layout>
	);
};

export default index;
