import * as React from "react";

function ARCTick() {
	return (
		<svg width="100%" viewBox="0 0 100 27.822">
			<g strokeWidth={2.364}>
				<path
					d="M59.888-.003c-7.96 0-12.647 6.137-12.647 13.91 0 7.775 4.687 13.912 12.647 13.912 5.84 0 10.525-4.649 11.121-10.973h-1.637c-.297 4.948-4.314 9.56-9.484 9.56-6.956 0-11.011-5.69-11.011-12.498 0-6.807 4.056-12.498 11.011-12.498 4.426 0 8.331 2.678 9.038 7.142h1.637c-.037-.372-1.265-8.555-10.675-8.555zM10.64.63L-.036 27.187h1.749l3.384-8.517h12.72l3.349 8.517h1.748L12.424.629zm14.17 0v26.558h1.636V15.098h9.45c4.648 0 5.913 2.083 6.248 4.65.298 2.566-.298 5.616.706 7.439h1.823c-1.079-1.414-.632-4.389-.892-7.142-.26-2.752-1.228-5.244-5.17-5.69v-.075c3.533-.595 5.69-3.236 5.69-6.807C44.3 2.34 40.544.63 35.895.63zm1.636 1.413h9.45c3.57 0 6.768 1.526 6.768 5.543 0 4.278-2.901 6.1-6.769 6.1h-9.449zm-14.914.187h.074l5.69 15.027H5.618z"
					fill="#231f20"
				/>
				<path d="M73.384 18.273v8.823h9.068z" fill="#b2c363" />
				<path d="M99.952 6.385L82.45 27.096h17.502z" fill="#b2c363" />
				<path d="M73.384.437v12.418l8.815 8.577L99.94.437z" fill="#0291d8" />
			</g>
		</svg>
	);
}

const MemoARCTick = React.memo(ARCTick);
export default MemoARCTick;
