import * as React from "react";

function EAIcon({ fill, size }) {
	return (
		<svg
			fill={fill || "currentcolor"}
			width={size || 132.21}
			height={size || 132.22}
			viewBox="0 0 132.21 132.22"
		>
			<path d="M17 0A16.973 16.973 0 00-.01 17.01v26.701L129.26 7.426A16.964 16.964 0 00115.192 0zm112.82 8.326L-.01 54.234 132.2 17.39v-.38c0-3.182-.88-6.143-2.389-8.684zm2.389 9.717L-.001 65.045l132.21-36.928zm0 10.59L-.001 75.858l132.21-36.744zm0 11.139L-.001 86.958l132.21-37.145zm0 10.521L-.001 97.479l132.21-36.799zm0 10.777L-.001 108.146l132.21-36.566zm0 10.781L.459 119.105l131.75-36.854zm0 10.742L5.769 127.978A16.936 16.936 0 0017 132.216h98.195c9.423 0 17.01-7.586 17.01-17.01z" />
		</svg>
	);
}

const MemoEAIcon = React.memo(EAIcon);
export default MemoEAIcon;
